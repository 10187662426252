import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Icon,
    IconButton,
    Input,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { query } from "firebase/database";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    onSnapshot,
    orderBy,
    updateDoc,
    where,
} from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import FormatMarkDown from "../../components/FormatMarkDown";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import useColors from "../../hooks/useColors";
import useWindowSize from "../../hooks/useWindowSize";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import createQAArray from "./createQAArray";
import queryData from "./queryData";
import RecordComponent from "./RecordComponent";
import RecordDialog from "./RecordDialog";

export default function QASystemPage({ flowId }) {
    const [displayPanel, setDisplayPanel] = useState("left");
    const [search, setSearch] = useState("");
    const [catalogs, setCatalogs] = useState([]);
    const [catalogId, setCatalogId] = useState("");
    const [currentCatalog, setCurrentCatalog] = useState();
    const [qaArray, setQAArrays] = useState([]);
    const [information, setInformation] = useState("");
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [prompt, setPrompt] = useState("");
    const [response, setResponse] = useState("Waiting for question...");
    const [queryId, setQueryId] = useState("");
    const [responseIds, setResponseIds] = useState([]);
    const [openAddFiles, setOpenAddFiles] = useState(false);
    const [files, setFiles] = useState([]);

    const { user } = useContext(AuthContext);

    const windowSize = useWindowSize();

    const colors = useColors();

    useEffect(() => {
        if (!user) return;

        const collectionRef = collection(db, "catalogs");
        const q = query(
            collectionRef,
            where("admins", "array-contains", user.id),
            where("flowId", "==", flowId),
            orderBy("date", "desc")
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const records = mapSnapshot(snapshot);
            setCatalogs(records);
        });

        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (!catalogId) return;

        const recordsRef = collection(db, "records");
        const q = query(recordsRef, where("catalogId", "==", catalogId), orderBy("date", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const records = mapSnapshot(snapshot);
            console.log("records: ", records);
            setRecords(records);
        });

        return unsubscribe;
    }, [catalogId]);

    useEffect(() => {
        if (!catalogId) return;

        const docRef = doc(db, "catalogs", catalogId);
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            const catalog = mapDocSnapshot(snapshot);
            setCurrentCatalog(catalog || null);
        });

        return unsubscribe;
    }, [catalogId]);

    useEffect(() => {
        setInformation(currentCatalog?.content || "");
        setQAArrays(createQAArray(currentCatalog?.content, currentCatalog?.title, catalogId));
        if (currentCatalog) console.log("Current catalog:\n", currentCatalog);
    }, [currentCatalog]);

    useEffect(() => {
        if (!queryId) return;

        const docRef = doc(db, "_firestore-vector-search/index/queries", queryId);
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            const response = mapDocSnapshot(snapshot);
            if (response?.result?.ids) {
                const ids = response.result.ids;
                setResponseIds(ids);
                console.log(ids);
            }
        });

        return unsubscribe;
    }, [queryId]);

    useEffect(() => {
        if (arrayIsEmpty(responseIds)) return;

        const loadData = async () => {
            const response = await queryData(responseIds, prompt);
            console.log("Response:\n", response);
            setResponse(response);
            setLoading(false);
            setPrompt("");
        };

        loadData();
    }, [responseIds]);

    const display = useMemo(() => {
        let left = 0;
        let mid = 0;
        let right = 0;
        let leftStyle = "flex";
        let midStyle = "flex";
        let rightStyle = "flex";
        let showBackArrow = false;

        if (windowSize.width < 600) {
            //Mobile view
            if (displayPanel === "left") {
                left = "100vw";
                mid = "0";
                leftStyle = "flex";
                midStyle = "none";
                rightStyle = "none";
            } else if (displayPanel === "mid") {
                left = "0";
                mid = "100vw";
                leftStyle = "none";
                midStyle = "flex";
                rightStyle = "none";
                showBackArrow = true;
            } else if (displayPanel === "right") {
                left = "0";
                mid = "0";
                right = "100vw";
                leftStyle = "none";
                midStyle = "none";
                rightStyle = "flex";
            }
        } else {
            // Desktop view
            left = windowSize.width * 0.25;
            mid = windowSize.width * 0.4;
            right = windowSize.width * 0.35;
        }

        return { left, mid, right, leftStyle, midStyle, rightStyle, showBackArrow };
        //
    }, [displayPanel, windowSize]);

    const handleAddRecord = async () => {
        if (!search) {
            toast.error("Please enter a name for the catalog");
            return;
        }
        const collectionRef = collection(db, "catalogs");
        const record = {
            date: new Date(),
            admins: [user.id],
            title: search,
            content: "Q: What is the project name?\nA: The project name is...",
            flowId,
        };
        const { id } = await addDoc(collectionRef, record);
        toast.success("Catalog added successfully");
        setCatalogId(id);
        setDisplayPanel("mid");
        setSearch("");
    };

    const handleClickCreate = async () => {
        const records = createQAArray(information, currentCatalog.title, catalogId);
        setQAArrays(records);
    };

    const handleSaveInformation = async () => {
        if (!currentCatalog) return;
        if (information === currentCatalog.content) return;
        const docRef = doc(db, "catalogs", currentCatalog.id);
        await updateDoc(docRef, { content: information, lastUpdated: new Date() });
        toast.success("Information saved successfully");
    };

    const confirmation = useConfirmation();

    const handleDeleteCatalog = async (id) => {
        const response = await confirmation(
            "Delete Catalog",
            "Are you sure you want to delete this catalog?"
        );

        if (response) {
            const docRef = doc(db, "catalogs", id);

            setLoading(true);
            await deleteDoc(docRef);
            setCatalogId("");
            toast.success("Catalog deleted successfully");

            // delete all the related records
            const recordsRef = collection(db, "records");
            const q = query(recordsRef, where("catalogId", "==", catalogId));
            const snapshot = await getDocs(q);
            const records = mapSnapshot(snapshot);
            await Promise.all(
                records.map(async (record) => {
                    const docRef = doc(db, "records", record.id);
                    await deleteDoc(docRef);
                    console.log("Record deleted: ", record.id);
                })
            );
            setRecords([]);
            setLoading(false);
        }
    };

    const handleIndex = async () => {
        const collectionRef = collection(db, "records");

        const response = await confirmation("Confirm Index", "Are you sure you want to index these records?");
        if (!response) return;

        setDisplayPanel("right");
        setLoading(true);
        await Promise.all(
            qaArray.map(async (item) => {
                // if item.input is already in record.input, skip
                if (records.some((record) => record.input === item.input)) {
                    console.log("Record already indexed:\n", item.id);
                    return;
                }
                const doc = { ...item, date: new Date(), admins: [user.id], flowId };
                await addDoc(collectionRef, doc);
                console.log("Indexed record:\n", doc);
            })
        );
        setLoading(false);
        toast.success("Records indexed successfully");
    };

    const handleSendPrompt = async () => {
        setLoading(true);
        const collectionRef = collection(db, "_firestore-vector-search/index/queries");
        const query = {
            query: prompt,
            limit: 8,
            prefilters: [
                {
                    field: "admins",
                    operator: "array-contains",
                    value: user.id,
                },
            ],
        };
        const { id } = await addDoc(collectionRef, query);
        console.log("Query ID:\n", id);
        setQueryId(id);
    };

    const handleAddFiles = (files) => {
        setFiles((prev) => [...prev, ...files]);
    };

    const handleAddFilesRecord = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const formValues = {
            question: data.get("question"),
            answer: data.get("answer"),
        };

        const collectionRef = collection(db, "records");

        const input = `Title: ${currentCatalog.title}\n Q: ${formValues.question}\nA: ${formValues.answer}`;
        const record = {
            date: new Date(),
            catalogId: catalogId,
            flowId,
            input,
            files,
            admins: currentCatalog.admins || [user.admin],
        };

        const { id } = await addDoc(collectionRef, record);

        if (!arrayIsEmpty(files)) {
            const link = `https://djcsystem.com/records/${id}`;
            const docRef = doc(db, "records", id);
            const newInput = `${input}\n${link}`;
            await updateDoc(docRef, { link, input: newInput });
        }

        toast.success("Record added successfully");
        setOpenAddFiles(false);
        setFiles([]);
    };

    const handleDeleteFile = (index) => {
        setFiles((prev) => prev.filter((_, i) => i !== index));
    };

    return (
        <Box display="flex" width="100vw" height={`calc(100vh - 56px)`}>
            {/* Left panel */}
            <Box
                width={display.left}
                height={"100%"}
                display={display.leftStyle}
                sx={{ borderRight: "1px solid grey" }}
                flexDirection={"column"}
            >
                {/* Top panel */}
                <Box
                    p={1}
                    width="100%"
                    height={"70px"}
                    sx={{ borderBottom: "1px solid grey" }}
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                >
                    {loading ? <CircularProgress /> : <Avatar />}
                    <Typography variant="body1">DJC QA System</Typography>
                </Box>

                <Box p={1} sx={{ borderBottom: "1px solid grey" }}>
                    <Input
                        disableUnderline
                        fullWidth
                        placeholder="Search / Add catalogs..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        sx={{
                            background: colors.searchBox,
                            borderRadius: "6px",
                            height: "35px",
                            padding: "0 8px 0 8px",
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                <Icon>search</Icon>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="start">
                                <Box display="flex" alignItems={"center"} gap={1}>
                                    <IconButton
                                        onClick={() => setSearch("")}
                                        size="small"
                                        sx={{ display: search ? "inherit" : "none" }}
                                    >
                                        <Icon color={"error"}>clear</Icon>
                                    </IconButton>

                                    <IconButton size="small" onClick={handleAddRecord}>
                                        <Icon>add</Icon>
                                    </IconButton>
                                </Box>
                            </InputAdornment>
                        }
                    />
                </Box>

                <Box
                    display="flex"
                    flex={1}
                    flexDirection={"column"}
                    sx={{
                        overflowY: "auto",
                        borderRight: "1px solid lightgrey",
                        borderBottom: "1px solid grey",
                    }}
                >
                    {catalogs.map((catalog) => (
                        <Box
                            key={catalog.id}
                            p={1}
                            display="flex"
                            alignItems={"center"}
                            sx={{
                                cursor: "pointer",
                                borderBottom: "1px solid grey",
                                "&:hover": {
                                    backgroundColor: colors.selectedBoxBackground,
                                    transition: "background-color 0.3s",
                                },
                                ...(catalogId === catalog.id && {
                                    backgroundColor: colors.selectedBoxBackground,
                                    color: colors.textColorMe,
                                    transition: "transform 0.3s, background-color 0.3s, color 0.3s",
                                }),
                            }}
                            onClick={() => {
                                setDisplayPanel("mid");
                                setCatalogId(catalog.id);
                            }}
                        >
                            <Typography variant="body1" flex={1}>
                                {catalog.title}
                            </Typography>
                            <IconButton onClick={() => handleDeleteCatalog(catalog.id)}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </Box>
                    ))}
                </Box>
                <Box
                    p={1}
                    sx={{ borderBottom: "1px solid grey", borderTop: "1px solid grey", overflowY: "auto" }}
                    height={"200px"}
                >
                    <FormatMarkDown text={response} />
                </Box>

                <Box p={1} sx={{ borderBottom: "1px solid grey" }}>
                    <TextField
                        multiline
                        rows={5}
                        fullWidth
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                handleSendPrompt();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={handleSendPrompt} disabled={loading}>
                                    {loading ? <CircularProgress /> : <Icon>send</Icon>}
                                </IconButton>
                            ),
                        }}
                    />
                </Box>
            </Box>
            {/* end of left panel */}

            {/*  mid panel */}
            <Box
                width={display.mid}
                display={display.midStyle}
                flexDirection={"column"}
                height={"100%"}
                sx={{ borderRight: "1px solid grey" }}
            >
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    sx={{ borderBottom: "1px solid grey" }}
                    height={"70px"}
                >
                    <Box display={display.showBackArrow ? "block" : "none"}>
                        <IconButton onClick={() => setDisplayPanel("left")}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    </Box>
                    <Box>
                        <Typography variant="h5">{currentCatalog?.title}</Typography>
                    </Box>
                </Box>

                <Box p={1}>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        placeholder="Your Q&A here..."
                        value={information}
                        onChange={(e) => setInformation(e.target.value)}
                        onBlur={handleSaveInformation}
                        disabled={!catalogId}
                    />
                </Box>

                <Box p={1} pt={0}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClickCreate}
                        disabled={!catalogId}
                    >
                        Add Q&A
                    </Button>
                </Box>

                <Box
                    sx={{
                        overflowY: "auto",
                        borderRight: "1px solid lightgrey",
                        borderBottom: "1px solid grey",
                        borderTop: "1px solid lightgrey",
                    }}
                    width="100%"
                    height="100%"
                    p={1}
                    flex={1}
                    display="flex"
                    flexDirection={"column"}
                >
                    {qaArray.map((record, index) => (
                        <Box key={record.id} p={1} sx={{ borderBottom: "1px solid grey" }}>
                            <Typography variant="caption">{index + 1}</Typography>
                            <Typography variant="body1" whiteSpace={"pre-wrap"}>
                                {record.input}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box
                    p={1}
                    sx={{ borderBottom: "1px solid grey" }}
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                >
                    <Button variant="contained" color="warning" onClick={handleIndex} disabled={!catalogId}>
                        Confirm and Index
                    </Button>
                    {windowSize.width < 600 && (
                        <Button
                            variant="contained"
                            onClick={() => setDisplayPanel("right")}
                            disabled={!catalogId}
                        >
                            Go to index page
                        </Button>
                    )}
                </Box>
            </Box>
            {/* end of mid panel */}

            {/* Right panel */}
            <Box width={display.right} display={display.rightStyle} flexDirection={"column"} height={"100%"}>
                <Box
                    display="flex"
                    alignItems={"center"}
                    gap={1}
                    p={1}
                    sx={{ borderBottom: "1px solid grey" }}
                    height={"70px"}
                >
                    <Box display={windowSize.width < 600 ? "block" : "none"}>
                        <IconButton onClick={() => setDisplayPanel("mid")}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                    </Box>
                    <Typography>Indexed Records</Typography>
                    <Button
                        variant="contained"
                        onClick={() => setOpenAddFiles(true)}
                        size="small"
                        disabled={!catalogId}
                    >
                        Add Files
                    </Button>
                </Box>
                <Box
                    sx={{
                        overflowY: "auto",
                        borderRight: "1px solid lightgrey",
                        borderBottom: "1px solid grey",
                    }}
                    width="100%"
                    height="100%"
                    p={1}
                    flex={1}
                    display="flex"
                    flexDirection={"column"}
                >
                    {records.map((record) => (
                        <RecordComponent key={record.id} record={record} catalogId={catalogId} />
                    ))}
                </Box>
            </Box>
            {/* end of right panel */}
            <RecordDialog
                files={files}
                handleAddFiles={handleAddFiles}
                handleAddFilesRecord={handleAddFilesRecord}
                handleDeleteFile={handleDeleteFile}
                openAddFiles={openAddFiles}
                setOpenAddFiles={setOpenAddFiles}
                title={currentCatalog?.title || ""}
            />
        </Box>
    );
}
