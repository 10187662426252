import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Paper,
    Select,
    TextField,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { green, red } from "@mui/material/colors";
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
    writeBatch,
} from "firebase/firestore";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Handle, Position, useReactFlow } from "reactflow";
import ImageComponent from "../../components/Image/ImageComponent";
import getThumbnail from "../../components/UploadFile/getThumbnail";
import isFileImage from "../../components/UploadFile/isFileImage";
import UploadFilesButton from "../../components/UploadFile/UploadFilesButton";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import useNotification from "../../context-utils/NotificationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import { GridContainer, GridDivider, Name } from "../../themes/themes";
import AIBlockNode from "./AIBlockNode";
import {
    removeAllFunctions,
    removeBlock,
    removeBlockAndFunctionEdge,
    removePoll,
    updateBlock,
} from "./firebaseCall";
import FlowContext from "./FlowContext";
import LoadScheduleComponent from "./LoadScheduleComponent";
import WhatsappFlowNode from "./WhatsappFlowNode";
import { Backdrop, CircularProgress } from "@mui/material";

const BlockNode = ({ data, isConnectable }) => {
    const { user } = useContext(AuthContext);
    const [dialog, setDialog] = useState(null);
    const { flow, setUpdateTrigger } = useContext(FlowContext);
    const [pollQuestion, setPollQuestion] = useState("Please choose from below");
    const [isLoading, setIsLoading] = useState(false);
    const [pollItems, setPollItems] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const confirmation = useConfirmation();
    const notification = useNotification();
    const [files, setFiles] = useState([]);
    const { setIsDeleting } = useContext(FlowContext);

    //rerender status to bring rerender to parent component.
    const triggerRerender = () => {
        setUpdateTrigger((prev) => prev + 1); // Increment trigger to cause re-render
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [blockObj, setBlockObj] = useState({
        timeUnit: "minutes",
        time: 0,
        message: "",
        count: null,
        name: "",
        status: "inactive",
        flowId: "",
        blockId: "",
        id: "",
    });

    const handleClickStatus = () => {
        const newStatus = blockObj.status === "active" ? "inactive" : "active";
        const updatedBlockObj = { ...blockObj, status: newStatus };
        setBlockObj(updatedBlockObj); // Update local state

        let docRef;

        if (blockObj.blockId) {
            docRef = doc(db, "users", user.id, "whatsappFlows", blockObj.flowId, "blocks", blockObj.blockId);
        } else {
            docRef = doc(db, "users", user.id, "whatsappFlows", blockObj.flowId, "blocks", blockObj.id);
        }

        updateDoc(docRef, { status: newStatus }).then(() => {
            setUpdateTrigger((prev) => prev + 1); // Trigger update in parent component
        });
    };

    const handleClickDelete = async () => {
        // First block of the flow cannot be deleted, cater it here.
        try {
            if (blockObj.orderId === 0) {
                notification("First step cannot be deleted.");
                return;
            }

            const response = await confirmation("Delete Block", "Confirm to delete block?");

            if (response) {
                setIsDeleting(true);
                // Delete edge that is connected to this block, all functions inside this block, and its edges.
                console.log("blockObj:", blockObj);

                await removeBlockAndFunctionEdge(user, flow, blockObj);
                // Remove the current block
                await removeAllFunctions(user, flow, blockObj);
                // Remove the current block
                await removeBlock(user, flow, blockObj);

                notification("Block deleted.");
                // Trigger rerender only after all removals are done
                triggerRerender();
            }
        } catch (err) {
            console.log(err.message);
        } 
    };

    const updateBlockObjInFirebase = async (updatedObj) => {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", updatedObj.id);
        await updateDoc(docRef, updatedObj);
    };

    const debouncedUpdate = useCallback(
        _.debounce((updatedObj) => updateBlockObjInFirebase(updatedObj), 1000),
        [] // Empty dependency array
    );

    const reactFlowInstance = useReactFlow();

    const handleCreateSubNode = async (user, flow, parentBlockObj, title, newBlockType,index) => {
        //sub node like poll is also a type of node.
        try {
            if (!reactFlowInstance) {
                console.error("React Flow instance is not available");
                return;
            }
            const collectionRef = collection(db, "users", user.id, "whatsappFlows", flow.id, "blocks");
            const q = query(collectionRef, where("blockType", "==", "sequential"));

            //to check if inside blocks there is no record.this part is to cater system migrating where previous system is using orderId for sequencing and 0 means that the block is the first item in the flow.
            const snapshot = await getDocs(q);
            let tempOrderId = snapshot.empty ? 0 : 999;

            //get arrPollItems to loop the count, get the count inorder to set the sub node position
            const parentRef = doc(
                db,
                "users",
                user.id,
                "whatsappFlows",
                flow.id,
                "blocks",
                parentBlockObj.id
            );
            const docSnapshot = await getDoc(parentRef);

            let newNodePositionY = 0;

            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                const arrPollItems = data.arrPollItems || [];

                // if (arrPollItems.length === 0) {
                //     newNodePositionY = 475;
                // } else {
                    newNodePositionY = 475 + index * 60;
                // }
            } else {
                console.log("No such parent node!");
            }

            ///need to add checker where if this is the first node, set orderId = 0
            const docRef = doc(collectionRef);

            await setDoc(
                docRef,
                {
                    id: docRef.id,
                    blockId: docRef.id, // Save the document ID
                    name: title,
                    createdBy: user.id,
                    status: "active",
                    flowId: flow.id,
                    count: 0,
                    time: 0,
                    timeUnit: "minutes",
                    orderId: tempOrderId,
                    message: "",
                    position: {
                        x: 20,
                        y: newNodePositionY,
                    },
                    updatedDate: "",
                    date: new Date(),
                    blockType: newBlockType,
                    parentNode: parentBlockObj.id,
                    extent: "parent",
                    type: "PollNode",
                    draggable: false,
                    reactFlowStatus: true,
                },
                { merge: true }
            );

            return docRef.id;
        } catch (err) {
            console.log("BlockNode.jsx:", err);
        }
    };

    const handleChangePollQuestion = (e) => {
        setPollQuestion(e.target.value);
    };

    const handleDeleteQuestion = async () => {
        const response = await confirmation(
            "Delete poll question and all selection",
            "Confirm to delete all poll question and selections?"
        );
        if (response) {
            if (blockObj.arrPollItems) {
                const batch = writeBatch(db);

                // First, add all block deletions to the batch
                for (const id of blockObj.arrPollItems) {
                    const blockRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", id);
                    batch.delete(blockRef);
                }

                // Commit the batch for block deletions
                await batch.commit();

                // Now handle the blocksRelation deletions
                for (const id of blockObj.arrPollItems) {
                    const q = query(
                        collection(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation"),
                        where("sourceNodeId", "==", id)
                    );

                    const snapshot = await getDocs(q);
                    // Delete each document found in the query
                    snapshot.docs.forEach((docSnapshot) => {
                        deleteDoc(docSnapshot.ref); // You can also use a batch here if the number of documents is significant
                    });
                }
            }

            let updateObj = {
                ...blockObj,
                pollObject: {},
                arrPollItems: [],
            };
            await updateBlock(user, flow, blockObj.id, updateObj);
            notification("Poll Question and selections deleted.");
            setPollItems([]);
            setPollQuestion("Please choose from below");
            triggerRerender();
            handleClose();
        }
    };

    const handleDeletePollItem = (index) => {
        const newItems = pollItems.filter((_, i) => i !== index);
        setPollItems(newItems);
    };

    const handleAddPollItem = () => {
        if (pollItems.length >= 12) {
            notification("Maximum limit for poll selection is 12.", "error");
            return;
        }
        setPollItems([...pollItems, "New Poll Selection"]);
    };

    const handleChangePollItem = (e, index) => {
        const newItems = [...pollItems];
        newItems[index] = e.target.value;
        setPollItems(newItems);
    };

    // const handleSavePollQuestion = async () => {
    //     let defaultQuestion = "";
    //     if (!pollQuestion.trim()) {
    //         defaultQuestion = "Please choose from below";
    //     } else {
    //         defaultQuestion = pollQuestion;
    //     }

    //     let docRef;

    //     if (blockObj.id) {
    //         docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.id);
    //     } else {
    //         docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.blockId);
    //     }

    //     await updateDoc(docRef, {
    //         pollObject: {
    //             pollName: defaultQuestion,
    //             pollOptions: pollItems,
    //         },
    //     });
    //     triggerRerender();
    // };

    const handleUpdatePollItem = async (e, i) => {
        try {
            const checker = e.target.value; // Use the current value of the input
            let arrExistingOptions = blockObj.pollObject.pollOptions;

            // Remove current item index from arrExistingOptions to ensure it's not checking against itself
            arrExistingOptions = arrExistingOptions.filter((_, index) => index !== i);

            let pollItems = [...blockObj.pollObject.pollOptions]; // Ensure you're working with a fresh array that reflects the current state

            // Convert arrExistingOptions to lowercase and check if checker exists
            if (arrExistingOptions.map((option) => option.toLowerCase()).includes(checker.toLowerCase())) {
                // notification("Poll name cannot be the same. Changing to name_copy.", "error"); // Alert user
                pollItems[i] = `${checker}_copy`; // Change the current item's name to name_copy
            } else {
                pollItems[i] = checker; // Update with the new checker value if not a duplicate
            }

            setPollItems(pollItems); // Update the state with the new names

            // Update the pollOptions in the blockObj before sending to updateBlock
            let updateObj = { pollObject: { ...blockObj.pollObject, pollOptions: pollItems } };
            await updateBlock(user, flow, blockObj.id, updateObj);

            //update name in the invididual block cause of the duplicated name.
            let updatePollNameObj = { name: pollItems[i] };
            await updateBlock(user, flow, blockObj.arrPollItems[i], updatePollNameObj);

            triggerRerender(); // Assuming this method correctly reflects changes in the UI
        } catch (err) {
            console.log("handleUpdatePollItem err :", err);
            notification("Save error. Please try again.", "error");
        }
    };

    const handleFiles = async (files) => {
        let docRef;
        if (blockObj.blockId) {
            docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.blockId);
        } else {
            docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.id);
        }
        await updateDoc(docRef, { files: [files[0]] });
        notification("Files uploaded successfully.");
        triggerRerender();
    };

    const handleDeleteFile = async (fileToRemove) => {
        if (isLoading) return; // Prevent the function from proceeding if it's already loading

        setIsLoading(true);
        try {
            let id = "";
            if (blockObj.blockId) {
                id = blockObj.blockId;
            } else {
                id = blockObj.id;
            }
            const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const existingFiles = docSnap.data().files || [];
                const updatedFiles = existingFiles.filter(
                    (file) => file.downloadURL !== fileToRemove.downloadURL
                );
                await updateDoc(docRef, { files: updatedFiles });
                notification("File removed successfully.");
            }

            triggerRerender();
        } catch (err) {
            console.log("err:", err);
        } finally {
            setIsLoading(false);
        }
    };

    async function checkThumbnails(files) {
        if (files) {
            const newFiles = [...files];
            await Promise.all(
                newFiles.map(async (file, index) => {
                    if (isFileImage(file)) {
                        const thumbnailURL = await getThumbnail(file);
                        if (thumbnailURL) {
                            newFiles[index] = { ...file, thumbnailURL }; // Ensure the updated file object is correctly reassigned
                        }
                    }
                    return null; // Return null for non-image files or when no thumbnailURL is updated
                })
            );
            return newFiles; // Return the modified files array
        }
        return []; // Return an empty array if files is falsy to ensure function always returns an array
    }

    const handleSavePoll = async () => {
         if (pollItems.length < 2) {
             notification("A poll must have at least 2 selection.", "error");
             return;
         }
        let defaultQuestion = pollQuestion.trim() || "Please choose from below";

        // Create or update poll items
        const updatedPollIds = await Promise.all(
            pollItems.map(async (pollItem, index) => {
                if (index < (blockObj.arrPollItems?.length || 0)) {
                    // Update existing poll item
                    await updateBlock(user, flow, blockObj.arrPollItems[index], { name: pollItem });
                    return blockObj.arrPollItems[index];
                } else {
                    // Create new poll item
                    return await handleCreateSubNode(user, flow, blockObj, pollItem, "PollNode", index);
                }
            })
        );

        let docRef;
        if (blockObj.id) {
            docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.id);
        } else {
            docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.blockId);
        }

        // Update the document with new poll information
        await updateDoc(docRef, {
            pollObject: {
                pollName: defaultQuestion,
                pollOptions: pollItems,
            },
            arrPollItems: updatedPollIds,
        });

        // Update local state
        setBlockObj({
            ...blockObj,
            pollObject: {
                pollName: defaultQuestion,
                pollOptions: pollItems,
            },
            arrPollItems: updatedPollIds,
        });

        notification("Poll saved successfully.");
        triggerRerender();
        handleClose();
    };

    useEffect(() => {
        setBlockObj(data);

        if (data?.pollObject?.pollOptions) {
            setPollItems(data.pollObject.pollOptions);
        }

        if (data?.pollObject?.pollName) {
            setPollQuestion(data.pollObject.pollName);
        }
    }, [data]);

    useEffect(() => {
        const fetchImages = async () => {
            const collectionRef = collection(db, "users", user.id, "files");
            let id;
            if (blockObj.blockId) {
                id = blockObj.blockId;
            } else {
                id = blockObj.id;
            }
            const q = query(collectionRef, where("blockId", "==", id));

            const snapshot = await getDocs(q);
            const fileDetails = mapSnapshot(snapshot);
            if (Array.isArray(fileDetails) && fileDetails.length > 0) {
                
                const updatedFiles = await checkThumbnails(fileDetails);
                setFiles(updatedFiles);
                setImagePreviews(updatedFiles);
            } else {
                // Handle case where fileDetails is not an array or is empty
               
                setFiles([]);
                setImagePreviews([]);
            }
        };

        if (user.id) fetchImages();
    }, [user.id]);

    if (data.blockType === "action" || data.blockType === "trigger")
        return <AIBlockNode data={data} isConnectable={isConnectable} />;

    if (data.blockType === "whatsappFlow")
        return <WhatsappFlowNode data={data} isConnectable={isConnectable} />;

    return (
        <>
            <style>
                {`
        @keyframes blinkGlow {
            0%, 100% {
                box-shadow: 0 0 40px #f7c052; // Full intensity
            }
            50% {
                box-shadow: 0 0 20px #f7c052; // Reduced intensity
            }
        }
        .glowing {
            animation: blinkGlow 1s ease-in-out infinite;
            z-index: 999; // Added z-index
        }
    `}
            </style>
            {dialog && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{dialog.content}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>OK</Button>
                    </DialogActions>
                </Dialog>
            )}
            <div
                className="text-updater-node"
                style={{ width: "300px", zIndex: blockObj.name === "New Node Name" ? 999 : 0 }}
                onClick={() => console.log(blockObj)}
            >
                {blockObj.orderId === 0 ? (
                    ""
                ) : (
                    <Handle
                        type="target"
                        position={Position.Left}
                        isConnectable={isConnectable}
                        style={{
                            background: "#525252",
                            width: "15px",
                            height: "15px",
                            borderRadius: "5px",
                            opacity: 0.5,
                        }}
                    />
                )}

                <Paper
                    className={blockObj.message === "" ? "glowing" : ""}
                    sx={{
                        padding: "8px",
                    }}
                >
                    <Grid key={blockObj.blockId} container display="flex" spacing={1}>
                        <Grid item xs={6}>
                            {/*to pass in blockId,flowId,user to loadschedulecomponent*/}

                            <LoadScheduleComponent
                                blockId={blockObj.blockId}
                                flowId={blockObj.flowId}
                                user={user}
                                blockName={blockObj.name}
                            />
                        </Grid>

                        <Grid item xs={4} display="flex" justifyContent="flex-end">
                            {blockObj.orderId !== null &&
                                blockObj.orderId !== undefined &&
                                blockObj.orderId === 0 && <Chip color="warning" size="small" label="FIRST" />}
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="flex-end">
                            {blockObj.count !== null && blockObj.count !== undefined && (
                                <Chip
                                    color={blockObj.count !== 0 ? "primary" : "success"}
                                    size="small"
                                    label={blockObj.count}
                                    onClick={() => console.log(data)}
                                />
                            )}
                        </Grid>
                        <Grid item display={"flex"} xs={8}>
                            <Box display="flex" alignItems="center" gap="8px">
                                <TextField
                                    size="small"
                                    fullWidth
                                    label="Block Name"
                                    value={blockObj.name}
                                    onChange={(e) => {
                                        const updatedValue = { ...blockObj, name: e.currentTarget.value };
                                        setBlockObj(updatedValue); // Update the state
                                        debouncedUpdate(updatedValue); // Pass the updated value immediately
                                    }}
                                    className="nodrag"
                                />
                            </Box>
                        </Grid>
                        <Grid item display={"flex"} justifyContent={"flex-end"} xs={4}>
                            <IconButton size="small" onClick={() => handleClickStatus()}>
                                <Name color={blockObj.status === "active" ? green[500] : red[500]}>
                                    {blockObj.status || "inactive"}
                                </Name>
                            </IconButton>
                            <IconButton size="small" onClick={() => handleClickDelete()}>
                                <Icon>delete</Icon>
                            </IconButton>
                        </Grid>

                        <Grid item display="flex" xs={12} className="nowheel">
                            <TextField
                                size="small"
                                fullWidth
                                label="Message"
                                className="nodrag"
                                multiline
                                rows={10}
                                value={blockObj.message}
                                onChange={(e) => {
                                    const updatedValue = { ...blockObj, message: e.currentTarget.value };
                                    setBlockObj(updatedValue); // Update the state
                                    debouncedUpdate(updatedValue); // Pass the updated value immediately
                                }}
                            />
                        </Grid>

                        <Grid item display="flex" xs={4}>
                            <Box display={"flex"} alignContent={"center"}>
                                <UploadFilesButton handleFile={handleFiles} label="Add File" />
                            </Box>
                        </Grid>

                        <Grid item display="flex" xs={4}>
                            <TextField
                                size="small"
                                fullWidth
                                type="number"
                                autoComplete="off"
                                value={blockObj.time || 0}
                                onChange={(e) => {
                                    const updatedValue = { ...blockObj, time: Number(e.target.value) };
                                    setBlockObj(updatedValue);
                                    debouncedUpdate(updatedValue); // Pass the updated value directly
                                }}
                                className="nodrag"
                            />
                        </Grid>

                        <Grid item display="flex" xs={4}>
                            <Select
                                value={blockObj.timeUnit || "minutes"}
                                size={"small"}
                                fullWidth
                                className="nodrag"
                                onChange={(e) => {
                                    const updatedValue = { ...blockObj, timeUnit: e.target.value };
                                    setBlockObj(updatedValue);
                                    debouncedUpdate(updatedValue); // Pass the updated value directly
                                }}
                            >
                                <MenuItem value={"minutes"}>Minutes</MenuItem>
                                <MenuItem value={"hours"}>Hours</MenuItem>
                                <MenuItem value={"days"}>Days</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item display="flex" xs={12} flexDirection={"column"}>
                            <Button variant="contained" color="primary" onClick={() => handleClickOpen()}>
                                Add/Edit Function
                            </Button>
                        </Grid>

                        {blockObj.pollObject?.pollName?.trim() ? (
                            <Grid
                                item
                                xs={12}
                                style={{
                                    width: "300px",
                                    height: "70px",
                                    display: "flex",
                                    flexDirection: "column",
                                    wordWrap: "break-word",
                                }}
                            >
                                {blockObj.pollObject?.pollName}
                            </Grid>
                        ) : (
                            ""
                        )}
                        {blockObj?.arrPollItems
                            ? blockObj.arrPollItems.map((item, index) => {
                                  if (item === "") return;
                                  return (
                                      <Grid
                                          key={`${item}-${index}`}
                                          item
                                          xs={12}
                                          style={{
                                              width: "300px",
                                              height: "65px",
                                              display: "flex",
                                              flexDirection: "column",
                                          }}
                                      ></Grid>
                                  );
                              })
                            : ""}

                        <Box
                            sx={{
                                pt: 3,
                                display: "flex",
                                flexDirection: "column", // Ensure vertical layout
                                alignItems: "center", // Center items horizontally
                                gap: 2,
                                p: 2,
                                width: "100%", // Ensure the box takes the full width
                            }}
                        >
                            {data.files?.map((file, i) => (
                                <GridContainer key={file.downloadURL + i}>
                                    <Grid item display="flex" xs={6}>
                                        <ImageComponent image={file} width={["100px", "150px"]} />
                                    </Grid>
                                    <Grid item display="flex" xs={5}>
                                        <Name>{file.name}</Name>
                                    </Grid>

                                    <Grid item display="flex" xs={1}>
                                        <IconButton
                                            onClick={() => handleDeleteFile(file)}
                                            disabled={isLoading}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </Grid>
                                    <GridDivider />
                                </GridContainer>
                            ))}
                        </Box>
                    </Grid>
                </Paper>
                <Handle
                    type="source"
                    position={Position.Right}
                    isConnectable={isConnectable}
                    id={"SEQUENTIAL"}
                    style={{
                        background: "#525252",
                        width: "15px",
                        height: "15px",
                        borderRadius: "5px",
                        opacity: 0.5,
                    }}
                />
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="sm" // Make sure this is a valid value
                fullWidth
            >
                <DialogTitle>Add/Edit Function</DialogTitle>
                <DialogContent>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Add poll (*Maximum 12 poll selection)
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="pollQuestion"
                                        type="text"
                                        name="pollQuestion"
                                        value={pollQuestion || " "}
                                        onChange={handleChangePollQuestion}
                                        // onBlur={handleSavePollQuestion}
                                        placeholder="Poll Question"
                                        label="Poll Question"
                                        inputProps={{ maxLength: 60 }}
                                    />
                                </Grid>

                                {pollItems?.map((item, i) => (
                                    <React.Fragment key={`${i}`}>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoFocus
                                                type="text"
                                                placeholder="Add poll selection"
                                                label="Add poll selection"
                                                value={item}
                                                onChange={(e) => handleChangePollItem(e, i)}
                                                inputProps={{ maxLength: 60 }}
                                            />
                                        </Grid>
                                        {/* <Grid
                                            item
                                            xs={1}
                                            justifyContent="center"
                                            alignItems={"center"}
                                            display="flex"
                                            onClick={() => handleDeletePollItem(i)}
                                        >
                                            <DeleteForeverIcon fontSize="large" />
                                        </Grid> */}
                                    </React.Fragment>
                                ))}

                                <Grid item xs={12} container alignItems="center" direction="column">
                                    <Button onClick={handleAddPollItem}>
                                        <AddCircleIcon fontSize="large" /> Add poll item
                                    </Button>
                                </Grid>

                                <Grid item xs={10}></Grid>

                                <Grid item xs={2} justifyContent="flex-end" display="flex">
                                    <Button onClick={handleSavePoll} >
                                        Save Poll
                                    </Button>
                                    <Button onClick={handleDeleteQuestion}>
                                        Delete Poll
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Back</Button>
                </DialogActions>
            </Dialog>
            {/* <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isDeleting}>
                <CircularProgress color="inherit" />
            </Backdrop> */}
        </>
    );
};

export default BlockNode;
