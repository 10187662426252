import axios from "axios";
import { collection, doc, getDocs, query, updateDoc, where } from "@firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import { notification } from "../../utils-functions/notification";

export async function subscribeToPage(page, currentUser) {
    //
    let subscribedFields = "leadgen,messages,message_deliveries,message_echoes,messaging_postbacks";

    console.log("subscribedFields:", subscribedFields);

    try {
        const url = `https://graph.facebook.com/v19.0/${page.id}/subscribed_apps?access_token=${page.accessToken}&subscribed_fields=${subscribedFields}`;
        const response = await axios.post(url);
        console.log("subscribeToPage response:", response.data);

        if (response.data.success) {
            notification(
                "Success",
                `Successfully subscribed to ${page.name} with ${subscribedFields}`,
                "success"
            );

            const usersCollectionRef = collection(db, "users");
            const queryForCurrentUser = query(usersCollectionRef, where("email", "==", currentUser.email));
            const userSnapshot = await getDocs(queryForCurrentUser);

            if (!userSnapshot.docs.length) {
                throw new Error("User not found");
            }

            let user = {
                ...userSnapshot.docs[0].data(),
                id: userSnapshot.docs[0].id,
            };

            let pagesSubscription = user.pagesSubscription || [];
            if (!pagesSubscription.includes(page.id)) {
                pagesSubscription.push(page.id);
                const userRef = doc(db, "users", user.id);
                await updateDoc(userRef, { pagesSubscription });
                console.log(`${page.name} Page subscription added to Firestore`);
            } else {
                console.log("Already in subscription.");
            }
        } else {
            notification("Oops", "Error subscribing to Facebook Page", "danger");
        }

        return response.data.success;
    } catch (err) {
        const errMessage = err?.response?.data?.error?.message || err.message;
        if (errMessage.includes("permission")) {
            notification("Oops", "Permission has yet to be granted to subscribe to this page", "danger");
        } else if (errMessage?.toLowerCase()?.includes("invalid")) {
            notification(
                "Oops",
                "Invalid access token, please reconnect, or this is not a Facebook Page",
                "danger"
            );
        } else {
            notification("Oops", err.message, "danger");
        }
        console.error(err);
    }
}
