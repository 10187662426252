import { Box, Typography, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useTheme } from "@emotion/react";
import { useState } from "react";

// Hardcoded message data for testing
// const hardcodedMessage = {
//   mid: "m_1234567890",
//   text: "Please answer this poll:",
//   elements: [
//     JSON.stringify({
//       title: "What's your favorite programming language?",
//       buttons: [
//         { title: "JavaScript", payload: "js_option" },
//         { title: "Python", payload: "python_option" },
//         { title: "Java", payload: "java_option" },
//         { title: "C++", payload: "cpp_option" }
//       ]
//     })
//   ],
//   attachments: [],
//   created_time: 1621234567,
//   is_echo: false,
//   app_id: 1234567890,
//   metadata: null
// };

export default function PollComponent({ message }) {
  const theme = useTheme();
  const [selectedOption, setSelectedOption] = useState(null);

  // Use the elements array directly, no need to parse
  const pollElements = message?.elements || [];

  // Flatten all buttons from all elements
  const options = pollElements.flatMap(element => 
    element.buttons.map(button => ({
      value: button.payload,
      label: button.title,
    }))
  );

  // Use the title of the first element as the main question
  const question = pollElements[0]?.title || '';

  // If no valid options are found, don't render the poll
  if (options.length === 0) {
    console.error("No valid options found for the poll");
    return null;
  }

  const handleSelectOption = (event) => {
    setSelectedOption(event.target.value);
    // Here you can add logic to send the selected option back to the server
  };

  return (
      <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="250px"
          p="16px"
          borderRadius="8px"
          border="1px solid #dcdcdc"
          sx={{ backgroundColor: theme.palette.mode === "light" ? "#dcf8c6" : "#075e54" }}
      >
          <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
              <Typography
                  variant="h6"
                  fontWeight="bold"
                  color={theme.palette.mode === "light" ? "textSecondary" : "textPrimary"}
              >
                  {question}
              </Typography>
              <Box display="flex" alignItems="center" mt="4px" width="100%">
                  <CheckCircleIcon style={{ color: "grey", marginRight: "4px", fontSize: "12px" }} />
                  <Typography
                      variant="caption"
                      color={theme.palette.mode === "light" ? "grey" : "textPrimary"}
                  >
                      Select one
                  </Typography>
              </Box>
          </Box>
          <RadioGroup
              value={selectedOption}
              onChange={handleSelectOption}
              style={{ width: "100%" }}
           
          >
              {options.map((option, index) => (
                  <FormControlLabel
                      key={index}
                      value={option.value}
                      control={
                          <Radio
                              icon={<RadioButtonUncheckedIcon />}
                              checkedIcon={<CheckCircleIcon style={{ color: "#25D366" }} />}
                          />
                      }
                      label={
                          <Box display="flex" flexDirection="column" width="100%">
                              <Box display="flex" alignItems="center">
                                  <Typography
                                      variant="body2"
                                      color={theme.palette.mode === "light" ? "textSecondary" : "textPrimary"}
                                      mr="8px"
                                  >
                                      {option.label}
                                  </Typography>
                              </Box>
                              <Box
                                  width="170px"
                                  bgcolor="#e0e0e0"
                                  borderRadius="4px"
                                  overflow="hidden"
                                  mt="4px"
                              >
                                  <Box
                                      bgcolor={selectedOption === option.value ? "#25D366" : "#d3d3d3"}
                                      width="100%"
                                      height="8px"
                                  />
                              </Box>
                          </Box>
                      }
                      style={{ width: "100%", margin: 0 }}
                  />
              ))}
          </RadioGroup>
      </Box>
  );
}
