// Import necessary modules and initialize Firestore
import {
    getFirestore,
    collection,
    addDoc,
    getDocs,
    query,
    where,
    deleteDoc,
    updateDoc,
    orderBy,
    writeBatch,
    getDoc,
    doc,
} from "firebase/firestore";
import { db, storage } from "../../firebase/firebase-utils";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Define the function to save data
export const addBlockRelation = async (user, flow, relationObj) => {
    try {
        const collectionRef = collection(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation");
        const docRef = await addDoc(collectionRef, relationObj); // Add the document with the provided data
        console.log("New Block Relation ID: ", docRef.id);
        return docRef.id; // Optionally return the document ID
    } catch (e) {
        console.error("Error adding document: ", e);
        throw e; // Optionally rethrow the error for handling in the calling component
    }
};

export const readBlock = async (user, flow, id) => {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const block = { id: docSnap.id, ...docSnap.data() };
            return block; // Return the single block document
        } else {
            console.log("No such document!");
            return null; // Handle the case where the document does not exist
        }
    } catch (e) {
        console.error("Error reading document: ", e);
        throw e; // Optionally rethrow the error for handling in the calling component
    }
};

// export const readBlockRelation = async (user, flow) => {
//     try {
//         const collectionRef = collection(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation");
//         const snapshot = await getDocs(collectionRef);

//         const blockRelations = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

//         return blockRelations; // Return the array of block relations
//     } catch (e) {
//         console.error("Error reading documents: ", e);
//         throw e; // Optionally rethrow the error for handling in the calling component
//     }
// };

export const readBlockRelation = async (user, flow) => {
    try {
        const collectionRef = collection(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation");
        const snapshot = await getDocs(collectionRef);

        const blockRelations = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

        // Check for duplicates
        const uniqueRelations = new Map();
        const duplicatesToRemove = [];

        blockRelations.forEach((relation) => {
            const key = `${relation.sourceNodeId}-${relation.targetNodeId}`;
            if (uniqueRelations.has(key)) {
                duplicatesToRemove.push(relation);
            } else {
                uniqueRelations.set(key, relation);
            }
        });

        // Remove excess duplicates from Firestore
        if (duplicatesToRemove.length > 0) {
            console.log(`Found ${duplicatesToRemove.length} excess duplicate relations. Removing...`);
            const batch = writeBatch(db);
            duplicatesToRemove.forEach((duplicate) => {
                const docRef = doc(collectionRef, duplicate.id);
                batch.delete(docRef);
            });
            await batch.commit();
            console.log("Excess duplicates removed successfully.");
        }

        return Array.from(uniqueRelations.values());
    } catch (e) {
        console.error("Error reading documents: ", e);
        throw e;
    }
};

export const removeBlockRelation = async (user, flow, relationObj) => {
    try {
        const q = query(
            collection(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation"),
            where("sourceNodeId", "==", relationObj.sourceNodeId),
            where("targetNodeId", "==", relationObj.targetNodeId)
        );

        const snapshot = await getDocs(q);
        await Promise.all(snapshot.docs.map((doc) => deleteDoc(doc.ref)));

        console.log(
            "Deleted documents with sourceNodeId: ",
            relationObj.sourceNodeId,
            " and targetNodeId: ",
            relationObj.targetNodeId
        );
    } catch (e) {
        console.error("Error deleting documents: ", e);
        throw e;
    }
};

export const removeBlockRelationContain = async (user, flow, blockId) => {
    try {
        const q = query(
            collection(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation"),
            where("sourceNodeId", "==", blockId)
        );

        const snapshot = await getDocs(q);
        await Promise.all(snapshot.docs.map((doc) => deleteDoc(doc.ref)));

        console.log("Deleted relation documents with sourceNodeId contains ", blockId);
    } catch (e) {
        console.error("Error deleting documents: ", e);
        throw e;
    }
};

const findSequenceFromStartToEnd = (startNode, edges) => {
    let sequence = [startNode];
    let current = startNode;

    while (current) {
        const nextEdge = edges.find((edge) => edge.sourceNodeId === current);

        if (!nextEdge) {
            break;
        }

        current = nextEdge.targetNodeId;
        sequence.push(current);
    }

    return sequence;
};

export const updateAllSequence999 = async (user, flow) => {
    try {
        const batch = writeBatch(db);

        // Fetch the documents you want to update
        const q = query(collection(db, "users", user.id, "whatsappFlows", flow.id, "blocks"));
        const snapshot = await getDocs(q);

        // Add each document to the batch with the new orderId, except where orderId is 0
        snapshot.forEach((doc) => {
            if (doc.data().orderId !== 0) {
                const nodeDocRef = doc.ref; // Reference to the document
                batch.update(nodeDocRef, { orderId: 999 });
            }
        });

        // Commit the batched writes to the database
        await batch.commit();
    } catch (err) {
        console.log(err);
    }
};

export const updateBlock = async (user, flow, blockId, itemObj) => {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockId);
        await updateDoc(docRef, itemObj); // Update the document with the provided data
    } catch (e) {
        console.error("Error updating document: ", e);
        throw e; // Optionally rethrow the error for handling in the calling component
    }
};

export const removeBlock = async (user, flow, blockObj) => {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            await deleteDoc(doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.id));
            console.log("removeBlock deleted.");
            //remove all functions that belongs to this block as well.then remove all edges that connect from this block and functions as well.
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (e) {
        console.error("Error getting document: ", e);
        throw e;
    }
};

export const removeAllFunctions = async (user, flow, blockObj) => {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let arrFunction = docSnap.data().arrPollItems;

            if (arrFunction && arrFunction.length > 0) {
                // Loop through arrFunction and delete each document
                for (const functionId of arrFunction) {
                    const functionDocRef = doc(
                        db,
                        "users",
                        user.id,
                        "whatsappFlows",
                        flow.id,
                        "blocks",
                        functionId
                    );
                    await deleteDoc(functionDocRef);
                }
                console.log("All functions deleted.");
            }
        } else {
            console.log("No such document!");
            return null;
        }
    } catch (e) {
        console.error("Error in removeAllFunctions: ", e);
        throw e;
    }
};

export const removePoll = async (user, flow, blockObj) => {
    try {
        const blocksCollectionRef = collection(db, "users", user.id, "whatsappFlows", flow.id, "blocks");
        const q = query(blocksCollectionRef, where("type", "==", "PollNode"));

        const querySnapshot = await getDocs(q);
        let found = false;

        for (const blk of querySnapshot.docs) {
            if (blk.id === blockObj.blockId) {
                found = true;
                const parentBlockId = blk.data().parentNode;

                // Remove edges that connect from this function
                await removeBlockRelationContain(user, flow, blockObj.blockId);

                // Update arrPollItems of parent Block/parentNode
                const parentDocRef = doc(
                    db,
                    "users",
                    user.id,
                    "whatsappFlows",
                    flow.id,
                    "blocks",
                    parentBlockId
                );
                const parentDocSnap = await getDoc(parentDocRef);

                if (parentDocSnap.exists()) {
                    let arrFunction = parentDocSnap.data().arrPollItems;

                    if (arrFunction) {
                        arrFunction = arrFunction.filter((item) => item !== blockObj.blockId); // Correctly filter out the blockId

                        let arrNames = [];
                        for (let x = 0; x < arrFunction.length; x++) {
                            const blks = await readBlock(user, flow, arrFunction[x]);
                            arrNames.push(blks.name);
                        }

                        console.log("arrNames::::::::", arrNames);

                        let latestObj = {
                            arrPollItems: arrFunction,
                            pollObject: {
                                ...parentDocSnap.data().pollObject,
                                pollOptions: arrNames,
                            },
                        };
                        console.log("latestObj::::::::", latestObj);
                        await updateBlock(user, flow, parentBlockId, latestObj);

                        //update each block in arrFunction for latest positionY
                        arrFunction.map(async (blkId, index) => {
                            let postY = 0;
                            if (index === 0) {
                                postY = 475;
                            } else {
                                postY = 475 + index * 60;
                            }

                            let updateObj = {
                                position: {
                                    x: 20,
                                    y: postY,
                                },
                            };
                            await updateBlock(user, flow, blkId, updateObj);
                        });
                    } else {
                        console.log("arrPollItems is empty.");
                    }
                } else {
                    console.log("No such parent document!");
                }

                //updated each arrPollItems positionY cause of the deletion.

                // Delete block
                await deleteDoc(
                    doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.blockId)
                );

                break; // Exit loop after processing the required document
            }
        }

        if (!found) {
            console.log("No such document with the specified blockId!");
        }

        // Additional handling for scheduleNextTask might be needed here
    } catch (e) {
        console.error("Error in removePoll: ", e);
        throw e;
    }
};

export const removeBlockAndFunctionEdge = async (user, flow, blockObj) => {
    try {
        let docRef;
        if (blockObj.blockId) {
            docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.blockId);
        } else {
            docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocks", blockObj.id);
        }
        const blockDoc = await getDoc(docRef);

        if (blockDoc.exists()) {
            let arrFunction = blockDoc.data().arrPollItems;
            if (arrFunction && arrFunction.length > 0) {
                // Loop through each item in arrFunction
                for (const sourceNodeId of arrFunction) {
                    const q = query(
                        collection(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation"),
                        where("sourceNodeId", "==", sourceNodeId)
                    );

                    const snapshot = await getDocs(q);
                    // Delete each document found in the query
                    for (const doc of snapshot.docs) {
                        await deleteDoc(doc.ref);
                    }
                }
            }

            //remove all sourceNodeId that match
            const q = query(
                collection(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation"),
                where("sourceNodeId", "==", blockObj.id)
            );
            const snapshot = await getDocs(q);
            for (const doc of snapshot.docs) {
                await deleteDoc(doc.ref);
            }

            //remove all targetNodeId that match
            const qTarget = query(
                collection(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation"),
                where("targetNodeId", "==", blockObj.id)
            );
            const snapshotTarget = await getDocs(qTarget);
            for (const doc of snapshotTarget.docs) {
                await deleteDoc(doc.ref);
            }
        }
    } catch (e) {
        console.error("Error in removePoll: ", e);
        throw e;
    }
};

// Revised waitForCondition function with a retry limit and error handling
const waitForCondition = async (conditionFunction, maxRetries = 5, delay = 1000) => {
    let attempts = 0;
    while (attempts < maxRetries) {
        if (await conditionFunction()) {
            return true; // Condition met, exit function
        }
        attempts++;
        await new Promise((resolve) => setTimeout(resolve, delay)); // Wait for the specified delay
    }
    // After exceeding max retries, throw an error or handle it as needed
    throw new Error("Condition not met within max retry attempts");
};

export const addImages = async (user, flow, blockId, files) => {
    const collectionRef = collection(db, "users", user.id, "whatsappFlows", flow.id, "files");
    try {
        const uploadTasks = files.map(async (file) => {
            const fileRef = ref(storage, `files/${user.id}/${file.name}`);
            await uploadBytes(fileRef, file);

            const url = await getDownloadURL(fileRef);

            const thumbnailFileName = `${file.name.split(".").slice(0, -1).join(".")}_1080x1080.jpeg`;
            const thumbnailRef = ref(storage, `files/${user.id}/${thumbnailFileName}`);

            // Wait for the thumbnail to be available
            await waitForCondition(() =>
                getDownloadURL(thumbnailRef)
                    .then(() => true)
                    .catch(() => false)
            );

            // Once available, get the thumbnail URL
            const thumbnailURL = await getDownloadURL(thumbnailRef);

            // Add a document to Firestore with the file and thumbnail URLs
            const docRef = await addDoc(collectionRef, {
                size: file.size,
                date: new Date(),
                uploadedBy: user.id,
                name: file.name,
                folderRef: `files/${user.id}`,
                type: file.type || "image/jpeg",
                contentType: file.type || "image/jpeg",
                fullPath: `files/${user.id}/${file.name}`,
                downloadURL: url,
                thumbnailURL: thumbnailURL, // Save the thumbnail URL here
                flowId: flow.id,
                blockId: blockId,
                id: blockId,
            });

            return { id: docRef.id };
        });

        // Wait for all uploads and document creations to complete
        const results = await Promise.all(uploadTasks);

        results.map(async (result) => {
            // await updateBlock(user, flow, blkId, {arrImages:});
            console.log(result.id);
        });
        await updateBlock(user, flow, blockId, { arrImages: results });
        console.log("Files saved:");
        return results;
    } catch (error) {
        console.error("Error saving files:", error);
        throw new Error("Failed to save images.");
    }
};

export const removeBlockRelationById = async (user, flow, id) => {
    try {
        // Directly reference the document to be deleted
        console.log(user, flow, id);
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation", id);

        // Delete the document
        await deleteDoc(docRef);

        console.log("Deleted document with id: ", id);
    } catch (e) {
        console.error("Error deleting document: ", e);
        throw e;
    }
};

export const readBlockRelationById = async (user, flow, documentId) => {
    try {
        console.log(user, flow, documentId);
        const docRef = doc(db, "users", user.id, "whatsappFlows", flow.id, "blocksRelation", documentId);
        const docSnapshot = await getDoc(docRef);

        if (!docSnapshot.exists()) {
            console.error("Document does not exist:", documentId);
            return null; // or throw an error if preferred
        }

        return { id: docSnapshot.id, ...docSnapshot.data() };
    } catch (e) {
        console.error("Error reading document:", e);
        throw e;
    }
};
