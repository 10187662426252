import DeleteIcon from "@mui/icons-material/Delete";
import { Icon, MenuItem, Select } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Container } from "@mui/system";
import {
    arrayRemove,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import DataFields from "../components/DataFields";
import { useConfirmation } from "../context-utils/ConfirmationContext";
import { ListContext } from "../context-utils/ListContext";
import { AuthContext } from "../context/AuthContext";
import { LeadsContext } from "../context/LeadsContext";
import { StateContext } from "../context/StateContext";
import { StoreContext } from "../context/StoreContext";
import { UsersContext } from "../context/UsersContext";
import { db } from "../firebase/firebase-utils";
import loadUsersRT from "../functions/loadUsersRT";
import useChooseFriend from "../hooks/useChooseFriend";
import {
    ButtonC,
    GridContainer,
    GridDivider,
    GridFlexBox,
    GridItem,
    Loading,
    Name,
    Title,
} from "../themes/themes";
import addState from "../utils-functions/addState";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";
import mapSnapshot from "../utils-functions/mapSnapshot";
import { arrayIsEmpty } from "./../utils-functions/arrayIsEmpty";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";

export default function UserCardPage() {
    const { user, setUser, setSignInAsUser } = useContext(AuthContext);
    const { users, setUsers, setUnsubscribeUsers } = useContext(UsersContext);
    const { openGlobalList } = useContext(ListContext);
    const { state, setState } = useContext(StateContext);
    const { quickAccesses, setQuickAccesses, clearStore } = useContext(StoreContext);
    const { setSourceLeads, unsubscribeSourceLeads, setAssignedLeads, unsubscribeLeads } =
        useContext(LeadsContext);
    const [teamMemberApproval, setTeamMemberApproval] = useState([]);
    const [accessApproval, setAccessApproval] = useState([]);
    const [signInAs, setSignInAs] = useState();
    const [displayUser, setDisplayUser] = useState();
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedApproval, setSelectedApproval] = useState(null);
    const [dialogAction, setDialogAction] = useState("");
    const { param } = useParams();
    const navigate = useNavigate();
    const confirm = useConfirmation();

    useEffect(() => {
        if (!user) return;
        setDisplayUser(user);
    }, [user]);

    useEffect(() => {
        if (users) return;
        // LoadAccessUsers();
        if (user.role === "Super Admin") {
            addState("authUser", user, state, setState);
            if (!users) loadData();
        } else {
            LoadAccessUsers();
        }

        if (param) {
            if (users) {
                const fUser = users.find((user) => user.id === param);
                if (fUser) setDisplayUser(fUser);
            }
        } else {
            setDisplayUser(user);
        }
    }, [user, users]);

    useEffect(() => {
        if (!state?.authUser) return;

        const loadData = async (authUser) => {
            const collectionRef = collection(db, "users", authUser.email, "quickAccesses");
            const q = query(collectionRef, orderBy("lastAccessed", "desc"));
            const snapshot = await getDocs(q);
            const quickAccesses = mapSnapshot(snapshot);
            setQuickAccesses(quickAccesses);
        };

        if (state.authUser) {
            loadData(state.authUser);
        }
    }, [state?.authUser]);

    useEffect(() => {
        const loadTeamMemberApprovals = async () => {
            const approvalQuery = query(
                collection(db, "approvals"),
                where("masterAccountId", "==", user.id),
                where("type", "==", "subAccountApproval"),
                where("status", "==", "pending")
            );
            const approvalSnapshot = await getDocs(approvalQuery);
            let approvalDocs = mapSnapshot(approvalSnapshot);

            const newTeamMemberApproval = [];
            approvalDocs.forEach((doc) => {
                if (doc.index && doc.index > 0) {
                    newTeamMemberApproval[doc.index - 1] = doc;
                }
            });

            setTeamMemberApproval(newTeamMemberApproval);
        };

        loadTeamMemberApprovals();
    }, [displayUser, user.id]);

    useEffect(() => {
        // if (!displayUser?.addOnItems?.includes("masterAccount")) return;

        console.log("loadAccessApprovals", user.id);

        const loadAccessApprovals = async () => {
            const accessQuery = query(
                collection(db, "approvals"),
                where("subAccountId", "==", user.id),
                where("type", "==", "subAccountApproval")
            );
            const accessSnapshot = await getDocs(accessQuery);
            let accessDocs = mapSnapshot(accessSnapshot);
            console.log(accessDocs.length);
            const newAccessApproval = [];
            accessDocs.forEach((doc) => {
                console.log(doc);
                if (doc.index && doc.index > 0) {
                    newAccessApproval[doc.index - 1] = doc;
                }
            });

            setAccessApproval(newAccessApproval);
        };

        loadAccessApprovals();
    }, [user.id]);

    const renderTeamMembers = () => {
        if (!displayUser?.addOnItems?.includes("masterAccount")) return null;

        const number = displayUser?.numberOfSubAccounts || 2;
        const array = Array.from({ length: number }, (_, index) => index + 1);

        return array.map((el) => {
            const subAccountId = displayUser?.subAccounts?.[el - 1];
            const approvalData = teamMemberApproval[el - 1];

            return (
                <React.Fragment key={el}>
                    <GridFlexBox w="30%" fs sx={{ backgroundColor: "red" }}>
                        <Name>{`Team member ${el}:`}</Name>
                    </GridFlexBox>
                    <GridFlexBox w="60%" fs>
                        {subAccountId ? (
                            <Name whiteSpace="nowrap">
                                {subAccountId}
                                <IconButton onClick={() => handleRemoveTeam(subAccountId, el)}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Name>
                        ) : approvalData ? (
                            <Name whiteSpace="nowrap">
                                {approvalData.subAccountId} ({approvalData.status})
                                <IconButton onClick={() => handleRemoveTeam(approvalData.subAccountId, el)}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Name>
                        ) : (
                            <IconButton onClick={() => handleAddTeam(el)}>
                                <Icon>add</Icon>
                            </IconButton>
                        )}
                    </GridFlexBox>
                    <GridFlexBox w="10%" fe></GridFlexBox>
                </React.Fragment>
            );
        });
    };

    const friendSelector = useChooseFriend();

    const updateQuickAccess = (user) => {
        const newQuickAccesses = quickAccesses.filter((item) => item.id !== user.id);
        setQuickAccesses([user, ...newQuickAccesses]);
    };

    const LoadAccessUsers = async () => {
        let users = [];
        const accessToIds = user.accessToIds || [];

        await Promise.all(
            accessToIds.map(async (id) => {
                const userRef = doc(db, "users", id);
                const snapshot = await getDoc(userRef);
                const tempUser = mapDocSnapshot(snapshot);

                if (!tempUser) return;
                users.push(tempUser);
            })
        );

        if (!arrayIsEmpty(users)) {
            setUsers([user, ...users]);
        }
    };

    const loadData = () => {
        loadUsersRT(user, setUsers, setUnsubscribeUsers, setLoading);
    };

    const handleClick = async (quickAccess) => {
        console.log(quickAccess);
        if (quickAccess) {
            signInAsFn(quickAccess);
            return;
        }

        const response = await openGlobalList(
            users,
            "Sign in as",
            "displayName",
            "face",
            false,
            true,
            "email"
        );

        if (response) {
            signInAsFn(response);
        }
    };

    const signInAsFn = async (member) => {
        if (!member?.id) return;

        // pull the latest user data
        const userRef = doc(db, "users", member.id);
        const snapshot = await getDoc(userRef);
        const signInUser = mapDocSnapshot(snapshot);

        // Save to quick access
        // user is the original master user
        // member is the user to sign in as
        const docRef = doc(db, "users", user.id, "quickAccesses", member.id);
        await setDoc(docRef, { ...signInUser, lastAccessed: new Date() });

        updateQuickAccess(signInUser);

        setDisplayUser(signInUser);
        setSignInAs(signInUser);
        setSignInAsUser(signInUser);
        setSourceLeads();
        setAssignedLeads();
        clearStore();
        unsubscribeSourceLeads?.();
        unsubscribeLeads?.forEach((fn) => fn?.());
    };

    const handleUpdate = (object, key, value) => {
        if (object.id === user.id) {
            const newUser = { ...user, [key]: value };
            setUser(newUser);
        }
    };

    const handleDeleteQuickAccess = async (id) => {
        const docRef = doc(db, "users", user.id, "quickAccesses", id);
        await deleteDoc(docRef);
        const newQuickAccesses = quickAccesses.filter((item) => item.id !== id);
        setQuickAccesses(newQuickAccesses);
    };

    const handleRemoveTeam = async (subAccountId, el) => {
        console.log(subAccountId);
        const response = await confirm(
            "Remove Team Member",
            "Are you sure you want to remove this sub account?"
        );

        if (response) {
            try {
                const approvalQuery = query(
                    collection(db, "approvals"),
                    where("masterAccountId", "==", user.id),
                    where("subAccountId", "==", subAccountId),
                    where("type", "==", "subAccountApproval")
                );
                const approvalSnapshot = await getDocs(approvalQuery);
                const approvalDocs = mapSnapshot(approvalSnapshot);

                if (approvalDocs.length > 0) {
                    const approvalRef = doc(db, "approvals", approvalDocs[0].id);
                    await deleteDoc(approvalRef);
                }

                if (user.id) {
                    const mainAccRef = doc(db, "users", user.id);
                    const docSnap = await getDoc(mainAccRef);
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        const latestSubAccounts = data.subAccounts || [];
                        const index = el - 1;
                        latestSubAccounts[index] = "";

                        await updateDoc(mainAccRef, {
                            subAccounts: latestSubAccounts,
                            accessToIds: arrayRemove(subAccountId),
                        });
                    } else {
                        console.error("Error approving access:", "Master account not found.");
                        toast.error("Master account not found.");
                    }

                    const userRef = doc(db, "users", subAccountId);
                    await updateDoc(userRef, {
                        superAccount: "none",
                        addOn: "none",
                    });

                    setTeamMemberApproval((prevApprovals) => {
                        const newApprovals = [...prevApprovals];
                        newApprovals[el - 1] = null;
                        return newApprovals;
                    });
                    // setAccessApproval((prevApprovals) =>
                    //     prevApprovals.filter((approval) => approval.id !== selectedApproval.id)
                    // );
                } else {
                    toast.error("Failed to remove access due to invalid masterAccountId.");
                    return;
                }

                // Updating subAccount details
            } catch (err) {
                console.log("Error removing subAccount or updating mainUser:", err);
            }
        }
    };

    const handleAddTeam = async (el) => {
        const subAccount = await friendSelector();

        if (subAccount) {
            // Check if the subAccount.id is already in the subAccounts array
            if (displayUser.subAccounts && displayUser.subAccounts.includes(subAccount.id)) {
                toast.error("This sub-account is already added to your team.");
                return;
            }

            let isAlreadyAdded = teamMemberApproval.some(
                (approval) => approval && approval.subAccountId === subAccount.id
            );

            if (isAlreadyAdded) {
                toast.error("This sub-account is already added to your team.");
                return;
            }
            const response = await confirm(
                "Add Team Member",
                "Are you sure you want to add this sub account?"
            );

            if (response) {
                try {
                    // Updating subAccount details
                    const userRef = doc(db, "users", subAccount.id);
                    const subAccountDoc = await getDoc(userRef);
                    if (subAccountDoc.exists()) {
                        const subAccountData = subAccountDoc.data();
                        if (subAccountData.superAccount && subAccountData.superAccount !== "none") {
                            toast.error("This sub-account is already assigned to a super account.");
                            return false;
                        }
                    } else {
                        toast.error("Sub-account document does not exist.");
                        return false;
                    }

                    // check if subAccount exisit, if exist, display it into team member.
                    // if is empty, check from approvals collection, if record exists, display it into team member
                    // if not exist, add record to approvals with default status pending, waiting the subaccount to approve.
                    const mainUserRef = doc(db, "users", user.id);
                    const mainUserDoc = await getDoc(mainUserRef);
                    const userData = mainUserDoc.data();
                    const subAccounts = userData.subAccounts || [];

                    if (subAccounts.includes(subAccount.id)) {
                        // SubAccount exists, display it in the UI
                        console.log("subAccount exists.");
                        toast.error("This sub-account is already assigned to your sub account.");
                        return false;
                    }

                    const approvalQuery = query(
                        collection(db, "approvals"),
                        where("masterAccountId", "==", user.id),
                        where("subAccountId", "==", subAccount.id)
                    );
                    const approvalSnapshot = await getDocs(approvalQuery);
                    let approvalDocs = mapSnapshot(approvalSnapshot);

                    if (approvalDocs.length > 0) {
                        // Approval record exists whether status pending or approved
                        setTeamMemberApproval((prevApprovals) => {
                            const newApprovals = [...prevApprovals];
                            newApprovals[el - 1] = approvalDocs[0];
                            return newApprovals;
                        });
                    } else {
                        const approvalRef = doc(collection(db, "approvals"));
                        const newApproval = {
                            status: "pending",
                            masterAccountId: user.id,
                            subAccountId: subAccount.id,
                            type: "subAccountApproval",
                            createdDate: new Date(),
                            index: el,
                        };
                        await setDoc(approvalRef, newApproval, { merge: true });
                        setTeamMemberApproval((prevApprovals) => {
                            const newApprovals = [...prevApprovals];
                            newApprovals[el - 1] = { ...newApproval, id: approvalRef.id };
                            return newApprovals;
                        });
                    }

                    // Add record to approvals

                    // Updating subAccount details
                    // const superAccount = user.id;
                    // const addOn = "whatsappCloud";
                    // await updateDoc(userRef, { superAccount, addOn });

                    // //subAccount expiry or access should follow superAccount as well. if superAccount expired/unsubscribe/upgrade, subAccounts should be same as well.

                    // // Checking and updating mainUser's subAccounts

                    // if (mainUserDoc.exists()) {
                    //     const userData = mainUserDoc.data();
                    //     const subAccounts = userData.subAccounts || [];

                    //     if (subAccounts.includes(subAccount.id)) {
                    //         toast.error("This team member is already part of your sub accounts.");
                    //         return;
                    //     }

                    //     // Ensure the array has enough elements, filling with an empty string if necessary
                    //     for (let i = 0; i < el; i++) {
                    //         if (!subAccounts[i]) {
                    //             subAccounts[i] = ""; // Fill with the desired value, e.g., an empty string
                    //         }
                    //     }

                    //     // Set the specific element at the given index
                    //     subAccounts[el - 1] = subAccount.id;

                    //     await updateDoc(mainUserRef, { subAccounts });
                    //     toast.success("Team member added successfully.");
                    // } else {
                    //     toast.error("User document does not exist.");
                    // }
                    toast.success("Team member invitation sent successfully.");
                } catch (err) {
                    console.log("Error updating subAccount or mainUser:", err);
                    toast.error("Failed to add team member. Please try again.");
                }
            }
        }
    };

    const handleOpenDialog = (approval, action) => {
        setSelectedApproval(approval);
        setDialogAction(action);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedApproval(null);
        setDialogAction("");
    };

    const handleApproveAccess = async () => {
        if (selectedApproval) {
            console.log(selectedApproval);
            try {
                // Update the approval status in Firestore
                const approvalRef = doc(db, "approvals", selectedApproval.id);
                await updateDoc(approvalRef, { status: "approved", approvedDate: new Date() });

                const mainAccRef = doc(db, "users", selectedApproval.masterAccountId);
                const docSnap = await getDoc(mainAccRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    let latestSubAccounts = data.subAccounts || [];
                    const index = selectedApproval.index;
                    latestSubAccounts[index - 1] = selectedApproval.subAccountId;

                    await updateDoc(mainAccRef, {
                        subAccounts: latestSubAccounts,
                        accessToIds: arrayUnion(user.id),
                    });
                } else {
                    console.error("Error approving access:", "Master account not found.");
                    toast.error("Master account not found.");
                }

                const userRef = doc(db, "users", selectedApproval.subAccountId);
                await updateDoc(userRef, {
                    superAccount: selectedApproval.masterAccountId,
                    addOn: "whatsappCloud",
                });

                // Update the local state
                setAccessApproval((prevApprovals) =>
                    prevApprovals.map((approval) =>
                        approval.id === selectedApproval.id ? { ...approval, status: "approved" } : approval
                    )
                );

                toast.success("Access approved successfully.");
            } catch (error) {
                console.error("Error approving access:", error);
                toast.error("Failed to approve access. Please try again.");
            }
        }
        handleCloseDialog();
    };

    const handleRemoveAccess = async () => {
        if (selectedApproval) {
            try {
                if (selectedApproval && selectedApproval.id) {
                    const approvalRef = doc(db, "approvals", selectedApproval.id);
                    await deleteDoc(approvalRef);
                } else {
                    console.error("Invalid approval data");
                    toast.error("Failed to remove access due to invalid data.");
                    return;
                }

                const userRef = doc(db, "users", user.id);
                await updateDoc(userRef, { superAccount: "none", addOn: "none" });

                if (selectedApproval && selectedApproval.masterAccountId) {
                    const mainAccRef = doc(db, "users", selectedApproval.masterAccountId);
                    const docSnap = await getDoc(mainAccRef);
                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        let latestSubAccounts = data.subAccounts || [];
                        const index = selectedApproval.index;
                        latestSubAccounts[index - 1] = "";
                        await updateDoc(mainAccRef, {
                            subAccounts: latestSubAccounts,
                            accessToIds: arrayRemove(user.id),
                        });
                    } else {
                        console.error("Error approving access:", "Master account not found.");
                        toast.error("Master account not found.");
                    }

                    setAccessApproval((prevApprovals) =>
                        prevApprovals.filter((approval) => approval.id !== selectedApproval.id)
                    );
                } else {
                    toast.error("Failed to remove access due to invalid masterAccountId.");
                    return;
                }

                toast.success("Access removed successfully.");
            } catch (error) {
                console.error("Error removing access:", error);
                toast.error("Failed to remove access. Please try again.");
            }
        }
        handleCloseDialog();
    };

    return (
        <>
            <Container maxWidth="sm">
                <GridContainer>
                    <GridFlexBox>
                        <Title>User Card</Title>
                    </GridFlexBox>
                    <Loading loading={loading} />
                    <GridDivider />
                    <GridFlexBox w="10%" fs>
                        <Icon fontSize="large">face</Icon>
                    </GridFlexBox>
                    <GridItem w="90%" fs fs20>
                        {displayUser && displayUser.displayName}
                    </GridItem>
                    <GridDivider />
                    <DataFields
                        object={displayUser}
                        collectionId={"users"}
                        handleUpdate={handleUpdate}
                        state={state}
                    />

                    <GridFlexBox w="30%" fs sx={{ backgroundColor: "red" }}>
                        <Name>Account Type:</Name>
                    </GridFlexBox>
                    <GridFlexBox w="60%" fs>
                        <Name whiteSpace="nowrap">
                            {displayUser?.addOnItems?.includes("masterAccount")
                                ? "Premium Plan"
                                : "Professional Plan"}
                        </Name>
                    </GridFlexBox>
                    <GridFlexBox w="10%" fe></GridFlexBox>
                    {renderTeamMembers()}

                    <GridDivider />

                    {accessApproval && accessApproval.length > 0 && (
                        <>
                            <GridFlexBox w="30%" fs sx={{ backgroundColor: "red" }}>
                                <Name>Add as sub account by:</Name>
                            </GridFlexBox>
                            <GridFlexBox w="60%" fs direction="column" alignItems="flex-start">
                                {accessApproval.map((approval, index) => (
                                    <GridFlexBox key={index} w="100%" fs alignItems="center" mb={1}>
                                        <Name whiteSpace="nowrap" mr={1}>
                                            {approval?.masterAccountId || "N/A"}
                                        </Name>
                                        <Name>({approval.status})</Name>
                                        {approval.status === "pending" ? (
                                            <Button onClick={() => handleOpenDialog(approval, "approve")}>
                                                Approve
                                            </Button>
                                        ) : (
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => handleOpenDialog(approval, "remove")}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </GridFlexBox>
                                ))}
                            </GridFlexBox>
                            <GridFlexBox w="10%" fe></GridFlexBox>
                            <GridDivider />
                        </>
                    )}
                    <SignInAsMember
                        state={state}
                        signInAs={signInAs}
                        handleClick={handleClick}
                        quickAccesses={quickAccesses}
                        handleClickQuickAccess={handleClick}
                        handleDeleteQuickAccess={handleDeleteQuickAccess}
                    />
                    <GridFlexBox fs w="50%">
                        <ButtonC color="warning" onClick={() => navigate(-1)} small>
                            Back
                        </ButtonC>
                    </GridFlexBox>
                    <GridFlexBox fe w="50%">
                        <ButtonC color="error" onClick={() => navigate("/sign-out")} small>
                            Sign Out
                        </ButtonC>
                    </GridFlexBox>
                    <GridDivider />
                </GridContainer>
            </Container>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>
                    {dialogAction === "approve" ? "Add as sub account" : "Withdraw as sub account"}
                </DialogTitle>
                <DialogContent>
                    {dialogAction === "approve"
                        ? `Are you sure you want to add as sub account by ${selectedApproval?.masterAccountId}?`
                        : `Are you sure you want to withdraw as sub account from ${selectedApproval?.masterAccountId}?`}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button
                        onClick={dialogAction === "approve" ? handleApproveAccess : handleRemoveAccess}
                        color="primary"
                    >
                        {dialogAction === "approve" ? "Approve" : "Remove"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

function SignInAsMember(props) {
    const { signInAs, handleClick, quickAccesses, handleClickQuickAccess, handleDeleteQuickAccess } = props;
    const [selectedQuickAccess, setSelectedQuickAccess] = React.useState("");

    const handleQuickAccessChange = (event) => {
        setSelectedQuickAccess(event.target.value);
        handleClickQuickAccess(event.target.value);
        console.log(event.target.value);
    };

    return (
        <React.Fragment>
            <GridItem fs fs20 w="30%">
                Sign in as:
            </GridItem>
            <GridFlexBox fs w="70%">
                <ButtonC small onClick={() => handleClick()}>
                    {signInAs ? signInAs.displayName : "Select member"}
                </ButtonC>
                <Select
                    value={selectedQuickAccess}
                    onChange={handleQuickAccessChange}
                    displayEmpty
                    size="small"
                    renderValue={(selected) => {
                        if (selected === "") {
                            return <em style={{ color: "#888" }}>Quick Access</em>;
                        }
                        return selected.email;
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {quickAccesses.map((access) => (
                        <MenuItem key={access.id} value={access}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                                {access.email}
                                <IconButton
                                    size="small"
                                    onClick={(event) => {
                                        event.stopPropagation(); // Prevent the select menu from closing
                                        handleDeleteQuickAccess(access.id);
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </GridFlexBox>
            <GridDivider />
        </React.Fragment>
    );
}
