import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import formatDate from "../../functions/common-functions/formatDate";
import formatNumber from "../../functions/common-functions/formatNumber";
import { GridDivider, GridFlexBox, Loading, Name, Title } from "../../themes/themes";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";

export default function PaymentsPage() {
    const { user } = useContext(AuthContext);
    const [payments, setPayments] = useState([]);
    const [connectedStripeId, setConnectedStripeId] = useState("");
    const [loading, setLoading] = useState(false);
    const [link, setLink] = useState("");
    const [regenerated, setRegenerated] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [total, setTotal] = useState(0);

    const { param } = useParams();

    useEffect(() => {
        if (param === "completed") {
            setCompleted(true);
            const getData = async () => {
                const docRef = doc(db, "stripeAccounts", user.id);
                const stripeAccSnap = await getDoc(docRef);
                const stripeAcc = mapDocSnapshot(stripeAccSnap);
                await updateDoc(docRef, { completed: true });
                console.log("completed");

                const userRef = doc(db, "users", user.id);
                await updateDoc(userRef, { connectedStripeId: stripeAcc.stripeAccountId });
                console.log("updated user");
            };
            getData();
        }

        if (param === "error") {
            setLink("");
            handleCreateStripe();
        }
    }, []);

    useEffect(() => {
        const getData = async () => {
            if (!user.connectedStripeId) return;

            setConnectedStripeId(user.connectedStripeId);

            const collectionRef = collection(db, "stripeTransfers");
            const q = query(collectionRef, where("destination", "==", user.connectedStripeId));
            const snapshot = await getDocs(q);
            const payments = snapshot.docs.map((doc) => {
                let netTransfer = doc.data().amount - doc.data().amount_reversed;
                return {
                    ...doc.data(),
                    netTransfer,
                    id: doc.id,
                };
            });

            // calculate total
            const total = payments.reduce((acc, payment) => acc + payment.netTransfer, 0);
            setTotal(total);

            setPayments(payments);
            console.log(payments);
        };

        getData();
    }, [user]);

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "stripeAccounts", user.id), (doc) => {
            if (doc.exists()) {
                const { link } = doc.data();
                const { completed } = doc.data();
                const { payouts_enabled } = doc.data();

                setCompleted(completed);

                if (payouts_enabled) {
                    setCompleted(true);
                }

                const { stripeAccountId } = doc.data();

                if (stripeAccountId) {
                    setConnectedStripeId(stripeAccountId);
                }

                if (link) {
                    setLink(link);
                    setLoading(false);
                }
            }
        });

        return unsubscribe;
    }, []);

    const handleCreateStripe = async () => {
        if (!user) {
            toast.error("You must be logged in to create a Stripe account");
            return;
        }

        const data = {
            type: "standard",
            email: user.id,
            country: "MY",
        };

        // wait for onCreate function to be triggered
        const docRef = doc(db, "stripeAccounts", user.id);

        setLoading(true);

        if (param === "error") {
            setRegenerated(true);
            setDoc(docRef, { date: new Date(), stripeParams: data, link: "" }, { merge: true });
        } else {
            setDoc(docRef, { date: new Date(), stripeParams: data }, { merge: true });
        }
        console.log("Loading... stripe");
    };

    return (
        <Container maxWidth="sm">
            <Grid container display="flex">
                <Title>Payments</Title>
                <Loading loading={loading} />
                <GridDivider />

                {param !== "error" && (
                    <>
                        {connectedStripeId && (
                            <>
                                <GridFlexBox fs>
                                    <Name>{`Your Stripe payment account Id: ${connectedStripeId}`}</Name>
                                </GridFlexBox>
                                <GridFlexBox fs>
                                    <>
                                        <Box>
                                            <Name color={green[500]}>
                                                Your Stripe account has been created
                                            </Name>
                                            {!completed && (
                                                <a href={link} target="_blank" rel="noreferrer">
                                                    Complete Stripe Account onboarding
                                                </a>
                                            )}
                                        </Box>
                                    </>
                                </GridFlexBox>
                            </>
                        )}

                        <GridFlexBox fs>
                            {connectedStripeId ? (
                                ""
                            ) : (
                                <>
                                    <Box>
                                        <Name color={red[500]}>
                                            You have not connected your Stripe account yet
                                        </Name>
                                        <Button size="small" variant="contained" onClick={handleCreateStripe}>
                                            Create Stripe Account
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </GridFlexBox>
                    </>
                )}

                {param === "error" && (
                    <>
                        <Button
                            size="small"
                            variant="contained"
                            onClick={handleCreateStripe}
                            sx={{ marginRight: "8px" }}
                            disabled={loading}
                        >
                            Regenerate URL
                        </Button>
                        <a href={link} target="_blank" rel="noreferrer">
                            {link}
                        </a>
                    </>
                )}

                <GridDivider />

                <Box display="flex" flexDirection={"column"}>
                    {payments.map((payment) => (
                        <Box
                            display="flex"
                            alignItems={"center"}
                            gap={2}
                            key={payment.id}
                            justifyContent={"space-between"}
                        >
                            <Name>{formatDate(new Date(payment.created * 1000))}</Name>{" "}
                            <Name color={payment.netTransfer >= 0 ? green[500] : red[500]}>
                                {formatNumber(payment.netTransfer / 100, 2)}
                            </Name>
                        </Box>
                    ))}

                    <Divider />

                    <Box display={"flex"} justifyContent={"space-between"} my={1}>
                        <Typography>Total:</Typography>
                        <Typography variant="body2" sx={{ color: green[500] }}>
                            {formatNumber(total / 100, 2)}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Container>
    );
}
