import axios from "axios";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

async function sendFBImage(chatRoom, imageUrl, accessToken) {
    const recipientId = chatRoom.id.split("-")[1];
    const pageId = chatRoom.id.split("-")[0];
    const metadata = JSON.stringify({ fromDJC: true });

    let data = JSON.stringify({
        recipient: {
            id: recipientId,
        },
        message: {
            attachment: {
                type: "image",
                payload: {
                    url: imageUrl,
                    is_reusable: true,
                },
            },
            metadata,
        },
    });

    let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://graph.facebook.com/v19.0/me/messages?access_token=${accessToken}`,
        data: data,
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        await axios.request(config);

        return true;
    } catch (error) {
        console.log(error.response.data.error.message);
        return false;
    }
}

export default sendFBImage;
