import axios from "axios";

const sendInstagramMessage = async ({
    pageId,
    recipientId,
    messageText,
    accessToken,
    chatRoomId,
    name,
}: {
    pageId: string;
    recipientId: string;
    messageText: string;
    accessToken: string;
    chatRoomId: string;
    name: string;
}) => {
    //
    const data = JSON.stringify({
        pageId,
        recipientId,
        messageText,
        accessToken,
        chatRoomId,
        name,
    });

    const config = {
        method: "post",
        url: "https://sendinstagrammessage-pc6xwmvyrq-as.a.run.app/",
        headers: {
            "Content-Type": "application/json",
        },
        data,
    };

    try {
        const response = await axios.request(config);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export default sendInstagramMessage;
