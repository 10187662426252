import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";
import removeSpecialChars from "./common-functions/removeSpecialChars";
import formatPhoneNumber from "./common-functions/formatPhoneNumber";

export default async function signUp(fullName, email, phone, password, setUser, setAuthUser, signupReferral) {
    try {
        phone = formatPhoneNumber(phone);
        email = email.toLowerCase().trim();

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const displayName = capitalizeFirstLetter(fullName);

        await updateProfile(auth.currentUser, { displayName: displayName });

        console.log("Updated displayName");

        const authUser = userCredential.user;

        console.log(authUser);

        const user = await createNewUser(displayName, email, phone, setUser, authUser, signupReferral);

        setAuthUser(authUser);

        console.log(user, " is created");

        return true;
    } catch (err) {
        console.log(err);
        let message = err.message;

        if (err.code === "auth/email-already-in-use") {
            message = "This email is already in use. Please use another email";
        }
        notification("Error", message, "warning");
        return false;
    }
}

const createNewUser = async (displayName, email, phone, setUser, authUser, signupReferral) => {
    try {
        const userRef = doc(db, "users", email);
        const userData = {
            date: new Date(),
            email,
            phone,
            displayName,
            id: email,
            uid: authUser.uid,
            clientId: removeSpecialChars(email),
            referral: signupReferral.fullId,
            referralId: signupReferral.shortId,
        };
        console.log(userRef);
        await setDoc(userRef, userData, { merge: true });
        setUser(userData);
        console.log("Firebase user created", displayName);
        return userData;
    } catch (err) {
        console.log(err);
    }
};

function capitalizeFirstLetter(string) {
    return string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")
        .trim();
}
