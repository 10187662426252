import axios from "axios";
import sendInstagramMessage from "./sendInstagramMessage";

async function sendFBMessage(currentChatRoom, messageText, page) {
    const recipientId = currentChatRoom.id.split("-")[1];
    const pageId = currentChatRoom.id.split("-")[0];
    const metadata = JSON.stringify({ fromDJC: true });

    // Replace new line characters in the messageText with escaped new lines
    const formattedMessageText = messageText.replace(/\n/g, "\\n");

    if (page.loginType === "instagram") {
        const response = await sendInstagramMessage({
            pageId,
            recipientId,
            messageText: formattedMessageText,
            accessToken: page.accessToken,
            chatRoomId: currentChatRoom.id,
            name: currentChatRoom.name,
        });
        return { status: response };
    }

    let data = JSON.stringify({
        recipient: {
            id: recipientId,
        },
        message: {
            text: formattedMessageText,
            metadata,
        },
    });

    let configFb = {
        method: "post",
        url: `https://graph.facebook.com/v20.0/me/messages?access_token=${page.accessToken}`,
        data: data,
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        await axios.request(configFb);
        //
        return { status: true, message: "Message sent successfully" };
    } catch (error) {
        const errMessage = error.response?.data?.error?.message || JSON.stringify(error);
        console.log("sendFBMessage error:", errMessage);
        return { status: false, message: errMessage };
    }
}

export default sendFBMessage;
